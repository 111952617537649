export default defineNuxtRouteMiddleware((to) => {
    const authStore = useAuthStore()
    if (!authStore.isLoggedIn) {
        const runtimeConfig = useRuntimeConfig()
        const tenant = useTenantPinia()
        return navigateTo(`${runtimeConfig.public.AUTH_ORIGIN}${runtimeConfig.public.LOGIN_PATH}?redirect=${urlSafeBtoa(to.fullPath)}&from=liff&tenant=${tenant.tenant?.slug}`, {
            external: true,
        })
    }
})
